import { sendGetRequest, sendPutRequest } from '@/services/api.service'
import { API_LEADS } from '@/config/api.config'

export function getClientTracking(clientId) {
  return sendGetRequest(`${API_LEADS.BASE_API_URL}/${API_LEADS.CLIENT}/${clientId}/tracking`)
}

export function putClientTracking(clientId, payload) {
  return sendPutRequest(`${API_LEADS.BASE_API_URL}/${API_LEADS.CLIENT}/${clientId}/tracking`, payload)
}

export function getClientMonetization(clientId) {
  return sendGetRequest(`${API_LEADS.BASE_API_URL}/${API_LEADS.CLIENT}/${clientId}/monetization`)
}

export function putClientMonetization(clientId, payload) {
  return sendPutRequest(`${API_LEADS.BASE_API_URL}/${API_LEADS.CLIENT}/${clientId}/monetization`, payload)
}
